$animation-time: 0.35s;

.plusMinusIcon {
  width: 25px;
  height: 25px;
  transform-origin: center center;
  transition: transform $animation-time ease-in-out;

  .p2 {
    transform-origin: 25%;
    transition: transform $animation-time ease-in-out;
  }
}

.plusMinusIcon--minus {
  .p2 {
    transform: scaleX(0);
  }
  transform: rotate(90deg);
}
