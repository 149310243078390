@import "../../styles/mixins";
@import "../../styles/spacing";
@import "../../styles/typography";
@import "../../styles/responsive";

.expertsBlock {
  margin: 0;
  padding: $spacing-2;

  @include only-on(xl) {
    padding: $spacing-3 $spacing-4;
  }
}

.expertsList {
  color: black;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

.expert {
  flex: 0 0 110px;
  margin: 0 $spacing-1 $spacing-2;

  @include only-on(md) {
    flex: 0 0 125px;
    margin: 0 $spacing-2 $spacing-2;
  }

  @include only-on(lgUp) {
    flex: 0 0 165px;
  }
}

.expert__profile {
  img {
    width: 165px;
    height: 165px;

    @include only-on(sm) {
      width: 110px;
      height: 110px;
    }

    @include only-on(md) {
      width: 125px;
      height: 125px;
    }
  }
}

.expert__name {
  @extend .text_medium;
  color: $vroom-teal;
  display: inline-block;
  font-weight: 600;
  margin-top: $spacing-1;

  &:hover {
    color: $vroom-teal;
  }
}

.expert__description {
  @extend .text_small;
  margin: $spacing-1 0;
}

.expert__profile,
.expert__name,
.expert__description {
  width: 110px;

  @include only-on(md) {
    width: 125px;
  }

  @include only-on(lgUp) {
    width: 165px;
  }
}
