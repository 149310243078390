@import '../../styles/mixins';
@import '../../styles/typography';
@import '../../styles/responsive';

.faqBlock {
  max-width: 950px;
  margin-bottom: 150px;
  margin-left: auto;
  margin-right: auto;
}

.faq__question {
  @extend .titleText;
  margin-bottom: 15px;
}

.faq__answer {
  @extend .bodyText;
  margin-bottom: 30px;
}
