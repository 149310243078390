@import '../../styles/mixins';
@import '../../styles/typography';
@import '../../styles/responsive';

.faqCategory {
  border-bottom: solid 1px $vroom-dark-brown;
}

.faqCategory__header {
  padding: 20px 0px;
  display: flex;
  cursor: pointer;
}

.faqCategory__name {
  font-family: var(--omnes-font);
  font-weight: 500;
  flex: 1 1 0%;
  font-size: 20px;
  @include only-on(lgUp) {
    font-size: 26px;
  }
}
