@import '../../styles/mixins';
@import '../../styles/typography';
@import '../../styles/responsive';

.brainBuildingBasic {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.brainBuildingBasic__text {
  @extend .bodyText;
  padding: 15px;

  @include only-on(lgUp) {
    padding: 20px;
    font-size: 20px;
  }
}

.brainBuildingBasic__header {
  @extend .titleText;
  font-weight: 600;

  @include only-on(mdDown) {
    font-size: 30px;
    margin-bottom: 20px;
  }

  @include only-on(lgUp) {
    font-size: 30px;
    line-height: 20px;
  }
}

.brainBuildingBasic__body {
  font-size: 16px;
  margin-top: 5px;
  p {
    margin: 0px;
  }
  @include only-on(lgUp) {
    margin-top: 15px;
    font-size: 20px;
  }
}

.brainBuildingBasic__image {
  img {
    width: 100%;
    height: auto;
  }
}
