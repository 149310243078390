@import '../../styles/mixins';
@import '../../styles/spacing';
@import '../../styles/typography';
@import '../../styles/responsive';

.researchCategory {
  border-bottom: solid 1px $vroom-dark-brown;
}

.researchCategory__header {
  padding: $spacing-2 0;
  display: flex;
  cursor: pointer;

  @include only-on(lgUp) {
    padding: $spacing-3 0;
  }
}

.researchCategory__name {
  @extend .headline_sub_inner;
  flex: 1 1 0%;
}

.article {
  margin-top: $spacing-2;
}

.article__citation {
  @extend .text_small;
  color: black;

  p {
    margin-bottom: 0;
  }
}

.article__file {
  margin: $spacing-2 0 $spacing-3;

  a {
    @extend .text_small;
    font-weight: 600;
  }
}
