@import '../../styles/mixins';
@import '../../styles/typography';
@import '../../styles/responsive';

$track-height: $card-height + 50px;
$track-height-lg: $card-height-lg + 100px;

.cardFan {
  height: $track-height;

  @include only-on(lgUp) {
    height: $track-height-lg;
    margin-bottom: 150px;
  }
}

.cardFan__container {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.75s ease-in-out;

  @include only-on(mdDown) {
    transform: scale(0.66);
  }

  @include only-on(sm) {
    transform: translate(-25px, -20px) scale(0.6);
  }
}

.cardFan__cards {
  position: relative;
  left: 40%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: $card-width;
  height: $track-height;
  transition: transform 0.75s ease-in-out, left 0.75s ease-in-out, width 0.9s ease-in-out,
    right 0.75s ease-in-out;

  @include only-on(lgUp) {
    width: $card-width-lg;
    height: $track-height-lg;
  }
}

.cardFan__card {
  background-color: white;
  position: absolute;
  width: $card-width;
  height: $card-height;
  @include only-on(lgUp) {
    width: $card-width-lg;
    height: $card-height-lg;
  }
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
  transition: transform 0.75s ease-in-out;
}

$dr: 8;
$numCards: 5;
$card-spacing: 20px;
$trackWidth: $numCards * ($card-width + $card-spacing);
$trackWidth-lg: $numCards * ($card-width-lg + $card-spacing);

@for $i from 0 through $numCards {
  $deg: 5 * $dr - $dr * ($i + 1);

  .cardFan.cardFan--collapsed {
    .cardFan__card--#{$i} {
      transform-origin: bottom left;
      transform: rotate(#{$deg}deg);
    }
  }

  $shift: $trackWidth - ($i + 1) * ($card-width + $card-spacing);
  $shift-lg: $trackWidth-lg - ($i + 1) * ($card-width-lg + $card-spacing);
  .cardFan.cardFan--expanded {
    .cardFan__card--#{$i} {
      transform: translate($shift, 0);
      @include only-on(lgUp) {
        transform: translate($shift-lg, 0);
      }
    }
  }
}

.cardFan.cardFan--expanded {
  .cardFan__container {
    overflow-x: auto;
    overflow-y: hidden;
    // left: 0;
    left: 50%;
    width: calc(100vw);
    transform: scale(1) translate(-50%);

    &::-webkit-scrollbar {
      display: none;
    }
  }
  .cardFan__cards {
    left: 0;
    right: 0;
    width: $trackWidth + 50px;
    overflow-x: hidden;
    overflow-y: hidden;
    transform: translate(0%, -50%);
    padding-left: 50px;

    @include only-on(lgUp) {
      width: $trackWidth-lg + 100px;
    }
  }

  .cardFan__card {
    margin-left: 20px;
    margin-right: 20px;
  }

  .cardFan__card:first-child {
    margin-right: 50px;
    @include only-on(lgUp) {
      margin-right: 100px;
    }
  }
}
